import * as React from "react"
import { Link } from "gatsby"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <h1>where <br />you <br />goin <br />lad?</h1>
      <p><a href="/">go back</a></p>
    </main >
  )
}

export default NotFoundPage
